 <template>
  <div>
    <CModalExtended
      :title="$t('label.nueva')+' '+$t('label.machineCondition')"
      color="dark"
      size="lg"
      :close-on-backdrop="false"
      :show.sync="modalActive"
      class="modal-content-condicion-maquina"
      >
        <CRow class="mt-2">
          <CCol sm="12">
            <CInput
                :label="$t('label.machineCondition')"
                :horizontal="{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
                add-label-classes="required text-right"
                v-uppercase
                v-model.trim="$v.formMachineCondition.MachineConditionName.$model"               
                :is-valid="hasError($v.formMachineCondition.MachineConditionName)"
                :invalid-feedback="errorMessage($v.formMachineCondition.MachineConditionName)"
                :placeholder="$t('label.machineCondition')"
                maxlength="250"
            >
            </CInput>
          </CCol>
          <CCol sm="12"> 
            <CSelect
                :label="$t('label.requiresSuplier')"
                :horizontal="{label: 'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-7'}"
                add-label-classes="text-right"
                v-uppercase
                :options="selectOptionsFgSuplier"
                :value.sync="formMachineCondition.FgSuplier"
                v-model.trim="formMachineCondition.FgSuplier" 
                :is-valid="hasError($v.formMachineCondition.FgSuplier)"
                :invalid-feedback="errorMessage($v.formMachineCondition.FgSuplier)"             
            >
            </CSelect>
          </CCol>
        </CRow> 
         <template #footer>

        <CButton
          outline
          color="add"
          :disabled="isSubmit"
          @click.stop="submit"
        >
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>

        <CButton
          color="wipe"
          class="d-flex align-items-center"
          :disabled="isSubmit"
          @click.stop="toggle(false)"
        >
          <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
        </CButton>

      </template>   
    </CModalExtended>  
  </div>       
</template>

    
<script>
  import { FormMachineCondition } from '@/_validations/maquina/MaquinaValidation';
  import UpperCase from '@/_validations/uppercase-directive';
  import GeneralMixin from '@/_mixins/general';
  import ModalMixin from '@/_mixins/modal';
  import { mapState } from 'vuex';

//Data
  function data() {
    return {
      modalActive: this.modal,
      isSubmit: false, 
      formMachineCondition: {
        MachineConditionName: '',
        FgSuplier: 0,
        suplier: '',
        
      },
      selectOptionsFgSuplier: [
      { 
          value: 0, 
          label: 'NO'
      },
      { 
          value: 1, 
          label: this.$t('label.yes')
      }
    ],

    }
  }


async function submit(){
  try {
    this.$v.formMachineCondition.$touch();
    if (this.$v.formMachineCondition.$error) {
      throw this.$t('label.errorsPleaseCheck');
    }
    this.isSubmit = true;
    let MachineConditionJson = {
      MachineConditionName: this.formMachineCondition.MachineConditionName,
      FgSuplier: this.formMachineCondition.FgSuplier,
    };

    await this.$http.post("MachineCondition-insert", MachineConditionJson, { root: 'MachineConditionJson' })
      .then(response => {
        if (response.status === (200 || 201)) {
            const messageSuccess = response.data.data[0].Response;
            this.$emit('submited');
            this.modalActive = false; 
            this.notifySuccess({ text: messageSuccess });
            this.resetForm();
          }     
      }).catch(err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      }).then(() => {
        this.isSubmit = false;
      });
  } catch (e) {
  this.notifyError({ text: e });
  }
  this.$store.dispatch('maquina/getAlllist',{CompanyBranchId:this.branch.CompanyBranchId});
}


  function toggle(newVal) {
    if(!newVal)
      this.resetForm();

    this.modalActive = newVal;
  }

  function resetForm() {
    this.formMachineCondition.MachineConditionName = '';
    this.formMachineCondition.FgSuplier = 0;
    this.$v.$reset();
  }

export default {
  name: 'add-condicion-maquina',
  mixins: [
    GeneralMixin, ModalMixin
  ],
  props: {
    modal: Boolean
  },
  data,
  validations: FormMachineCondition,
  directives: UpperCase,
  methods: {
    submit,
    toggle,
    resetForm,
    
  },
  computed: {
    ...mapState({
              branch: state => state.auth.branch
          }),
  },
  watch: {
    modal: function(val) {
      this.toggle(val);
    },
    modalActive: function(val) {
      this.$emit('update:modal', val);
    }
  }
}
</script>
<style lang="scss">
.modal-content-condicion-maquina {
  .modal-content {
    width: 80% !important;
    margin: auto !important;
    .modal-body {
      padding-left: 1.7rem;
      padding-right: 1.7rem;
    }
  }
}
</style>
