<template>
    <div class="mb-3">
        <loading-overlay :active="isLoading" :is-full-page="true" loader="bars"/>
        <CRow class="mt-2">
            <CCol sm="12" class="d-flex justify-content-end py-2">
                <CButton
                color="add"
                class="d-flex align-items-center" 
                v-c-tooltip="{
                    content: $t('label.machineCondition'),
                    placement: 'top-end'
                }"
                @click="toggleAdd"
                >
                <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nueva')}}</span>
                </CButton>
            </CCol>
        </CRow>  
        <CRow class="mb-3">
            <CCol sm="12" >
                <dataTableExtended
     class="align-center-row-datatable"
                    :items="formatedItems"
                    :fields="fields"
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    sorter
                    :noItemsView="tableText.noItemsViewText"
                    :items-per-page="5"
                    pagination
                    column-filter
                    hover
                    size="lg"
                >
                    <template #RowNumber="{ item }">
                        <td class="center-cell">
                            {{ item.RowNumber }}
                        </td>
                    </template>
                    <template #Status="{item}">
                    <td class="center-cell">
                        <CBadge :color="getBadge(item.Status)">
                        {{ $t('label.'+item.Status) }}
                        </CBadge>
                    </td>
                    </template>
                    <template #acciones="{item}">
                        <td class="center-cell">
                                <CButton
                                    color="edit"
                                    size="sm"
                                    class="mr-1"
                                    v-c-tooltip="{
                                        content: $t('label.edit')+' '+$t('label.machineCondition'),   
                                        placement: 'top-end'
                                    }" 
                                    @click="toggleEdit(item)"  
                                >
                                    <CIcon name="pencil"/>
                                </CButton>
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
        </CRow>
        <add-condicion-maquina  :modal.sync="modalAdd" @submited="handleSubmit"/>
        <edit-condicion-maquina :modal.sync="modalEdit" :condition-data="formMachineCondition" @submited="handleSubmit"/>
    </div>   
</template>

<script>
    import GeneralMixin from '@/_mixins/general';
    import ModalMixin from '@/_mixins/modal';
    import AddCondicionMaquina from './add-condicion-maquina';
    import EditCondicionMaquina from './edit-condicion-maquina';
    import { DateFormater, tableTextTranslatedHelpers } from '@/_helpers/funciones';

    //data
    function data() {
        return {
            items: [],
            formMachineCondition: {},
            activePage: 1,
            isLoading: false,
            modalAdd: false,
            modalEdit: false
        };
    }

    //Created
    function created() {
        //this.getMachineCondition();
    }

    function getMachineCondition() {
        this.isLoading = true;

        this.$http.get('MachineCondition-list', {filter: 'ALL'})
            .then((response) => {
                this.items = response.data.data;
            }).catch( err => {
                this.$notify({
                group: 'container',
                title: '¡'+this.$t('label.error')+'!',
                text: err,
                type: "error"
                });
            }).then(() => {
                this.isLoading = false;
            });
    }

    function toggleAdd() {
    this.modalAdd = true;
    }
    function toggleEdit(item) {
    this.formMachineCondition = Object.assign({}, item);
    this.modalEdit = true;
    }

    function handleSubmit(){
    this.getMachineCondition();
    }

//Computed:
    function formatedItems() {
        return this.items.map((item) => Object.assign({}, item, {
            RowNumber: item.Nro,
            FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
            _classes: item.Status != 'ACTIVO'? 'table-danger': ''
        }));
    }

    function fields () {
        return [
            { key: 'RowNumber', label: '#', filter: false,_style: 'width:1%; text-align:center;', },
            { key: 'MachineConditionName',label: this.$t('label.machineCondition'), _style:'min-width:100px', _classes:'text-uppercase text-center center-cell' },
            { key: 'TransaLogin', label: this.$t('label.user'), _style: 'width: 15%;', _classes:'text-uppercase text-center center-cell'},
            { key: 'FormatedDate', label: this.$t('label.date'), _style: 'width: 15%; text-align:center;', _classes:'text-center center-cell'},
            { key: 'Status', label: this.$t('label.status'), _style:'width:15%;', _classes:'text-center center-cell'},
            { key: 'acciones', label: '', sorter: false, filter: false, _style: 'width:1%;', _classes:'text-center center-cell' }
        ]
    }

    function tableText() {
        return tableTextTranslatedHelpers(this);
    }
    export default {
        name:'app-condicion-maquina',
        mixins: [GeneralMixin, ModalMixin], 
        components: {
            AddCondicionMaquina, EditCondicionMaquina,
        },
        props:{Tab: Number},
        data,
        created,   
        methods:{
            getMachineCondition,
            toggleAdd,
            toggleEdit,
            handleSubmit
        },
        
        computed: {
            fields,
            formatedItems,
            tableText
        },
        mounted:getMachineCondition,
        watch: {
            Tab:function (val){
                if(val === 0){
                    this.getMachineCondition();
                }
            }
        }    
    } 
    
</script>
<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>